<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="filter">
            <!-- <div class="item">
                <div class="title">排序：</div>
                <el-select v-model="typeIndex" placeholder="请选择">
                    <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div> -->
        </div>
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index" @click="$router.push({ path: 'culturalDetail', query: { id: item.id } })">
                <div>
                    <img :src="item.picUrl" alt="" />
                    <span class="title">{{ item.name }}</span>
                </div>
            </div>
        </div>
        <el-pagination class="pagination" v-if="total" :current-page="pageNum" :page-size="pageSize" :total="total" @current-change="currentChange" background layout="prev, pager, next,jumper"></el-pagination>
    </div>
</template>

<script>
export default {
    name: 'about',
    data() {
        return {
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '文化人才',
                },
            ],
            typeIndex: 0,
            typeList: [
                { label: '默认', value: 0 },
                { label: '热门', value: 1 },
            ],
            pageNum: 1,
            pageSize: 16,
            total: 0,
            list: [],
        };
    },
    created() {
        this.getData();
    },
    watch: {
        typeIndex() {
            this.pageNum = 1;
            this.getData();
        },
    },
    methods: {
        getData() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                type: 0,
                familyId: this.$route.query.id || null,
            };
            if (this.typeIndex !== '') {
                params.sort = this.typeIndex;
            }
			if (this.$route.query.id != null) {
				console.log('aaa');
				this.$hao.getTalentFamilyDetail(this.$route.query.id).then((res) => {
				    this.list = res.data.cultureTalentFamilyList;
				    this.total = res.total;
				});
			} else {
				console.log('bbb');
				this.$hao.getTalentFamilyList(params).then((res) => {
				    this.list = res.rows;
				    this.total = res.total;
				});
			}

        },
        currentChange(val) {
            //滚动到页面顶部
            window.scrollTo(0, 0);
            this.pageNum = val;
            this.getData();
        },
    },
};
</script>

<style scoped lang="less">
.filter {
    display: flex;
    align-items: center;
    padding-bottom: 24px;

    .item {
        display: flex;
        align-items: center;

        .title {
            display: flex;
            width: max-content;
        }

        .el-select {
            width: 160px;
            margin-right: 24px;

            .el-input__inner {
                border: 1px solid #e4e7ed;
                box-sizing: border-box;
                border-radius: 4px;
                font-size: 14px;
                line-height: 17px;
                color: #606266;
            }
        }
    }
}

.list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 30px;
    .item {
        margin-bottom: 56px;
        cursor: pointer;

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        img {
            width: 175px;
            height: 175px;
            border-radius: 100%;

            filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
        }

        .title {
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            color: #333333;
            flex: none;
            order: 1;
            flex-grow: 0;
            margin-top: 13px;
            width: 90%;
            .ellipsisLine(1);
        }
    }
}
</style>
